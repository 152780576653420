import request from '@/utils/requestJava'

export function getShopHome(shopId, query) {
  return request({
    url: `/supplier-server/app-api/supplierShop/homePage/${shopId}/`,
    method: 'get',
    params: query
  })
}

export function getShopPetsList(data) {
  return request({
    url: `/product-server/app-api/petProductSpu/v1/selectList`,
    method: 'post',
    data
  })
}
export function getShopPetsInfo(query) {
  return request({
    url: `/supplier-server/app-api/petShop/v1/pagehome`,
    method: 'get',
    params: query
  })
}
