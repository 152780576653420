export function loadOpeninstall(open_data) {
  const script = document.createElement('script')

  script.type = 'text/javascript'

  script.src = 'https://web.cdn.openinstall.io/openinstall.js'

  document.getElementsByTagName('head')[0].appendChild(script)

  // 等待挂载完成
  script.onload = function() {
    var data = open_data.data// /openinstall.js中提供的api，解析当前网页url中的查询参数并对data进行赋值
    // data.type = type
    console.log(data)
    if (data.is_share) {
      delete data.is_share
    }
    console.log('o888888pen_data.data')
    new OpenInstall({ // 初始化方法，与openinstall服务器交互，应尽早调用
      appKey: 'zsq29r', // appKey为openinstall为应用分配的唯一id（必须传入）
      fastInstall: false, // 直接拉起去掉避免页面看不到活动
      onready: function() { // 初始化成功回调方法。当初始化完成后，会自动进入
        // this.schemeWakeup();//尝试使用scheme打开App（主要用于Android以及iOS的QQ环境中）
        var m = this; var button = document.getElementById('downloadButton')// 为button对象绑定对应id的元素
        button.onclick = function() { // 对应button的点击事件
          console.log('点击了')
          console.log(data)
          console.log('o888888pen_data.data')
          m.wakeupOrInstall()// 此方法为scheme、Universal Link唤醒以及引导下载的作用（必须调用且不可额外自行跳转下载）
          return false
        }
      }
    }, data)// 初始化时传入data，作为一键拉起/App传参安装时候的参数
  }
}
