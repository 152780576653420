import CallApp from 'callapp-lib'

const option = {
  scheme: {
    protocol: 'gpets',
    host: 'cn.gpets.app'
  },
  intent: {
    package: 'cn.gpets.app',
    scheme: 'gpetscheme'
  },
  universal: {
    host: 'www.gpets.cn',
    pathKey: ''
  },
  appstore: 'https://d.gpets.cn/down?f=100',
  yingyongbao: 'https://d.gpets.cn/down?f=100',
  fallback: 'https://d.gpets.cn/down?f=100',
  timeout: 15000// 等待时长
}

const dict2query = (dict) => {
  if (!dict) return ''
  let ret_str = ''
  Object.keys(dict).some((key) => {
    ret_str += ('&' + key + '=' + dict[key])
  })
  return ret_str
}
var hidden
var visibilityChange

function getSupportedProperty() {
  if (typeof document === 'undefined') return

  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden'
    visibilityChange = 'visibilitychange'
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden'
    visibilityChange = 'msvisibilitychange'
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden'
    visibilityChange = 'webkitvisibilitychange'
  }
}

getSupportedProperty()
function isPageHidden() {
  if (typeof hidden === 'undefined') return false
  return document[hidden]
}
function openAppPage(gpetsurl) {
  var start = Date.now()

  var iframe = document.createElement('iframe')

  iframe.style.display = 'none'

  iframe.src = gpetsurl

  document.body.appendChild(iframe)
  setTimeout(() => {
    console.log(isPageHidden(), 'isPageHidden()')
    if (isPageHidden()) {
      return
    }
    setTimeout(function() {
      var end = Date.now()
      console.log(end - start)
      if (!isPageHidden()) { // 如果应用未打开，提供下载链接
        window.location.href = 'https://d.gpets.cn/down'
      }
    }, 8000)
  }, 1500)
}
export const openApp = (info, callback) => {
  info.timestamp = parseInt(new Date().getTime() / 1000)
  const url = dict2query(info)
  option.yingyongbao += url
  option.fallback += url
  option.appstore += url
  option.logFunc = callback
  console.log(info)
  console.log(info.path)
  const lib = new CallApp(option)
  // 兼容安卓QQ浏览器 打开时,出现系统级缓存的问题
  info.timestamp = new Date().getTime()
  const gpetsurl = 'gpets://cn.gpets.app?path=' + info.path + '&U=' + info.timestamp
  // const gpetsurl = 'gpets://cn.gpets.app?path=gpets://d.gpets.cn/pet/shop/home?paramId=10021&U=' + info.timestamp

  var userAgent = navigator.userAgent.toLowerCase()
  console.log(userAgent)
  if (userAgent.indexOf('xiaomi/miuibrowser/18.6.71011') != -1) {
    // window.location.href = gpetsurl// 自定义跳转
    openAppPage(gpetsurl)
    return
  }
  // window.location.href = 'weixin://dl/businessWebview/url?timestamp=' + info.timestamp

  lib.open({
    param: info,
    path: 'gpets'
  })
}
