<template>
  <div
    v-if="user_info.data"
    class="user-info"
  >
    <div class="flex flex-v-c flex-bt">
      <img
        :src="user_info.data.avatar||'https://gpets.oss-cn-hangzhou.aliyuncs.com/v2gpets/1836651935470592000.png'"
        class="size-120 b-50p ml-60"
        alt=""
      >
      <div class="right-cross flex">
        <div>
          <div class="num">{{ user_info.data.followedQuantity }}</div>
          <div>关注</div>
        </div>
        <div>
          <div class="num">{{ user_info.data.fansQuantity }}</div>
          <div>粉丝</div>
        </div>
        <div>
          <div class="num">{{ user_info.data.authRole }}</div>
          <div>获赞</div>
        </div>
      </div>
    </div>

    <div class="ml-32">
      <div class="black-l ft-36 mt-20">{{ user_info.data.nickname }}</div>
      <div class="black-l ft-24 mt-20">{{ user_info.data.province }} {{ user_info.data.city }}</div>
      <div class="gray ft-26 limit-text desc mt-30">{{ user_info.data.intro }}</div>
    </div>
  </div>
  <div class="flex flex-h-c mt-48 mb-48">  <div class="shop-user">店铺</div></div>

  <div class="user-content-bottom">
    <van-tabs
      v-model:active="active"
      offset-top="700"
    >
      <van-tab title="动态">
        <div class="list-div ">
          <div
            v-for="(m,i) in moment_list.data"
            :key="'q'+i"
            class="list mb-60 pl-32 pr-32"
          >
            <div class="time mt-10 mb-10">{{ m.createDateTime }}</div>
            <div class="content-desc mb-24 mt-20">{{ m.content }}</div>
            <div
              v-if="m.mediaList.length"
              class="media"
            >
              <img
                v-if="m.mediaList[0].mediaType=='1'"
                :src="m.mediaList[0].mediaUrl"
                alt=""
              >
              <video
                v-else
                controls
                :src="m.mediaList[0].mediaUrl"
              />
              <div
                v-if="m.topicList&&m.topicList.length"
                class="topic-div flex"
              >
                <span class="topic-span">
                  #{{ m.topicList[0].name }}
                </span>
              </div>
            </div>

          </div>
        </div>
      </van-tab>
      <van-tab title="商品">
        <div class="list-div flex pet-list-div">
          <div
            v-for="(m,i) in pet_list.data"
            :key="'q'+i"
            class="list pet-list"
          >
            <div class="left">
              <img
                :src="m.defaultPictureUrl"
                alt=""
              >
            </div>
            <div class="right">
              <!--<img :src="m.url" alt="">-->
              <div class="desc">{{ m.spuTitle }}</div>
              <div class="flex-v-c flex flex-bt mt-16">
                <div class="money"><span>￥</span>{{ m.salePrice }}</div>
                <div class="wanted ">{{ m.likedQuantity }}人想要</div>
              </div>
            </div>
          </div>
        </div></van-tab>

    </van-tabs>
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from 'vue'
import { momentList, petList, userInfo } from '@/api/share'
import MomentItem from '@/views/share/component/MomentItem'

export default defineComponent({
  name: 'ShareUser',
  components: {
    MomentItem
  },
  props: ['uid'],
  setup(props) {
    const uid = ref(props.uid)
    const moment_list = reactive([])
    const user_info = reactive({})
    const pet_list = reactive([])
    const active = ref(0)
    const userParams = {
      userId: uid.value
    }
    userInfo(userParams).then(res => {
      user_info.data = res.data[0]
    })
    const params = {
      userId: uid.value,
      page_number: 1,
      page_size: 20
    }
    momentList(params).then(res => {
      moment_list.data = res.data
    })
    const petParams = {
      userId: uid.value,
      page_number: 1,
      platformType: 1,
      page_size: 20
    }
    petList(petParams).then(res => {
      pet_list.data = res.data
    })
    return {
      user_info,
      moment_list,
      pet_list,
      active
    }
  }
})
</script>

<style  lang="scss">
.user-info {
  background-color: white;
  //height: 288px;
  .desc{
    width: 100%;
    word-break: break-all;
  }
  div{
    box-sizing: border-box;
  }
  .right-cross{
    display: flex;
    justify-content: space-between;
    text-align: center;
    width: 300px;
    padding-right: 32px;
    color:gray ;
    font-size: 26px;
    .num{
      font-size: 28px;
      color:black;
      font-weight: bold;
    }
  }
}
.shop-user{
  width: 686px;
  height: 80px;
  line-height: 80px;
  background: rgba(255,255,255,0.39);
  box-shadow: 0px 4px 12px rgba(0,0,0,0.1);
  border-radius: 40px;
  text-align: center;
}

.user-content-bottom {
  background-color: white;
  margin-top: 40px;
  .list{
    padding-bottom: 60px;
    border-bottom: 1px solid #e2e2e2;
  }
  .pet-list-div{
    flex-wrap: wrap;
    justify-content: space-around;
    .pet-list{
      width:338px;
      img{
        width:338px;
        height:338px;
      }
      .right{
        .money{
          color: #000000;
          font-size: 28px;
        }
        .wanted{
          font-size: 22px;
          color: #676767;
        }
      }
    }
  }
  img,video{
    width: 100%;
    border-radius: 16px;
  }
  img{
    height: 600px;
    object-fit: cover;
  }
  div{
    box-sizing: border-box;
  }
  .time{
   font-size: 24px;
   color: gray;
  }
  .content-desc{

  }

  .topic-span{
    background: #f5f5f5;
    font-size: 22px;
    padding: 4px 16px;
    border-radius: 8px;
  }

}

</style>
