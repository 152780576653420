<template>
  <div class="share-shop-window">
    <div class="top ">
      <div
        class="search-top flex flex-v-c"
        @click="chooseTag"
      >
        <div class="search-img-out flex-v-c flex pl-24">
          <img
            src="https://gpets.oss-cn-hangzhou.aliyuncs.com/v2gpets/1851131946898432000.png"
            alt=""
            class="search-img"
          >
          <img
            class="search-btn-img"
            src="https://gpets.oss-cn-hangzhou.aliyuncs.com/v2gpets/1851134136841019392.png"
            alt=""
          >
          <span class="ml-4">搜索更多好物</span>
        </div>

        <img
          class="dot-img ml-32"
          src="https://gpets.oss-cn-hangzhou.aliyuncs.com/v2gpets/1851132530443558912.png"
          alt=""
        >
      </div>
      <div class="shop-info flex flex-bt flex-v-c mt-46">
        <div class="left inline-flex flex-v-c ">
          <img
            :src="shop_info.shopLogo||'https://gpets.oss-cn-hangzhou.aliyuncs.com/v2gpets/1836651935470592000.png'"
            alt=""
            class="shop-logo mr-20"
          >
          <div>
            <div class="shop-name mb-16 flex-v-c flex">
              <span class="name ft-32 bold">
                {{ shop_info.authRole=='10'?(user_info.nickname+'的橱窗'):shop_info.shopName }}
              </span>
              <span class="shop-tag ml-8 inline-flex flex-v-c pl-8">
                <img
                  v-if="shop_info.certified&&shop_info.authRole=='20'"
                  class="tag-img"
                  src="https://gpets.oss-cn-hangzhou.aliyuncs.com/v2gpets/1851139097960259584.png"
                  alt=""
                  @click="chooseTag"
                >
                <img
                  v-else-if="shop_info.certified"
                  class="tag-img tag-img-personal"
                  src="https://gpets.oss-cn-hangzhou.aliyuncs.com/v2gpets/1851139800187412480.png"
                  alt=""
                >
                <span>{{ shop_info.certified&&shop_info.authRole=='20'?'认证商家':shop_info.certified?'个人商家':'' }}</span>
                <img
                  v-if="shop_info.certified&&shop_info.authRole=='20'"
                  class="right-default"
                  src="https://gpets.oss-cn-hangzhou.aliyuncs.com/v2gpets/1851139282526412800.png"
                  alt=""
                >
              </span>
            </div>
            <div class="num-div">
              <span
                v-if="shop_info.fansQuantity"
                class="fans"
              >
                <span class="num">{{ shop_info.fansQuantity }}</span>
                粉丝
              </span>
              <span
                v-if="shop_info.fansQuantity"
                class="goods ml-30"
              >
                <span class="num">{{ shop_info.spuQuantity }}</span>
                商品
              </span>
            </div>
          </div>
        </div>
        <div
          class="right-div flex flex-v-c flex-h-c"
          @click="chooseTag"
        >

          <img
            src="https://gpets.oss-cn-hangzhou.aliyuncs.com/v2gpets/1851147049505005568.png"
            alt=""
            class="mr-20"
          >
          <span>+ 关注</span>

        </div>
      </div>

    </div>
    <div class="content-divs">
      <div
        v-if="shop_info.provinceName"
        ref="intro"
        class="intro limit-text mt-20 pl-32 pr-32 "
      >
        地址：{{ shop_info.provinceName }}
        {{ shop_info.cityName }}{{ shop_info.areaName }}{{ shop_info.detailAddress }}
        <!--        <span-->
        <!--          v-if="showLimit"-->
        <!--          class="up-down"-->
        <!--          :class="[showLimit&&showMore?'down':'up']"-->
        <!--        ></span>-->
      </div>
      <div
        class="tag flex flex-v-c flex-bt"
        :class="[data_info.detailAddress?'mt-32':'mt-36']"
      >
        <div>
          <div
            v-for="(m,i) in tagList"
            :key="i"
            class="tag-inner inline-flex"
            :class="[selectedIndex==i?'selected':'']"
            @click="chooseTag(i)"
          >
            {{ m.name }}
          </div>
        </div>
        <div>
          <div
            v-for="(m,i) in tagListRight"
            :key="i"
            class="tag-inner inline-flex "

            @click="chooseTag(i)"
          >
            {{ m.name }} <img
              v-if="i==1"
              :src="price_img"
              alt=""
            >
          </div>
        </div>
      </div>
      <div
        v-if="data_info.data.length!=0"
        class="list-div"
      >
        <div
          v-for="(m,i) in data_info.data"
          :key="i"
          class="list"
          @click="chooseTag(i)"
        >
          <img
            :src="m.defaultPictureUrl"
            alt=""
          >
          <div class="title limit-text2">
            {{ m.spuTitle }}
          </div>
          <div class="price flex flex-bt flex-v-c">
            <span class="price-span">￥{{ m.salePrice }}</span>
            <span>{{ m.buyQuantity }}人付款</span>
          </div>
        </div>

      </div>
      <div
        v-else
        class="empty"
      >
        <div>
          <img
            src="https://gpets.oss-cn-hangzhou.aliyuncs.com/v2gpets/1813874450299494400.png"
            alt=""
          >
        </div>
        暂无数据
      </div>
    </div>

  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, nextTick, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'ShareTopic',
  props: ['info', 'shop_info'],
  emits: ['openAppUrl'],
  setup(props, { emit }) {
    const data_info = reactive(props.info)
    const user_info = reactive(props.info.userInfo)
    const shop_info = reactive(props.info.shopInfo)
    const { proxy } = getCurrentInstance()
    const router = useRouter()

    const tagList = reactive([
      {
        name: '商品'
      },
      {
        name: '评价'
      }
    ])
    const tagListRight = reactive([
      {
        name: '上新'
      },
      {
        name: '价格'
      }
    ])
    const goodsList = reactive([
      { title: '「比乐」全价小型成犬粮全价小型成犬粮', price: 58600, payNum: 56323,
        src: 'https://gpets.oss-cn-hangzhou.aliyuncs.com/test/PYiwdKdriIYagEaPsdHs.png?x-oss-process=image/resize,w_750' },
      { title: '「比乐」全价小型成犬粮全价小型成犬粮', price: 58600, payNum: 56323,
        src: 'https://gpets.oss-cn-hangzhou.aliyuncs.com/test/PYiwdKdriIYagEaPsdHs.png?x-oss-process=image/resize,w_750' },
      { title: '「比乐」全价小型成犬粮全价小型成犬粮', price: 58600, payNum: 56323,
        src: 'https://gpets.oss-cn-hangzhou.aliyuncs.com/test/PYiwdKdriIYagEaPsdHs.png?x-oss-process=image/resize,w_750' },
      { title: '「比乐」全价小型成犬粮全价小型成犬粮', price: 58600, payNum: 56323,
        src: 'https://gpets.oss-cn-hangzhou.aliyuncs.com/test/PYiwdKdriIYagEaPsdHs.png?x-oss-process=image/resize,w_750' },
      { title: '「比乐」全价小型成犬粮全价小型成犬粮', price: 58600, payNum: 56323,
        src: 'https://gpets.oss-cn-hangzhou.aliyuncs.com/test/PYiwdKdriIYagEaPsdHs.png?x-oss-process=image/resize,w_750' },
      { title: '「比乐」全价小型成犬粮全价小型成犬粮', price: 58600, payNum: 56323,
        src: 'https://gpets.oss-cn-hangzhou.aliyuncs.com/test/PYiwdKdriIYagEaPsdHs.png?x-oss-process=image/resize,w_750' }
    ])
    const iconList = [require('../../../assets/share/upDefault.png'),
      require('../../../assets/share/downTwo.png'),
      require('../../../assets/share/upTwo.png')

    ]
    const price_img = ref(iconList[0])
    const data = reactive({
      shop_info: {
        shopName: '柯基小铺子',
        shopPcount: '5',
        shopNewcount: '68',
        intro: '品牌故事品牌故事 品牌故事品牌故事 品牌故事品牌故事 品牌故事品牌故事品牌故事品牌故事 品牌故事品牌故事品牌故事品牌故事 品牌故事品牌故事品牌故事品牌故事 品牌故事品牌故事。',
        avatarUrl: 'https://gpets.oss-cn-hangzhou.aliyuncs.com/v2gpets/nTkuEN5PhFQOjnRCHox5.jpg'
      }
    })
    const selectedIndex = ref(0)
    const chooseTag = (i) => {
      // selectedIndex.value = i
      // if (i == 1) {
      //   price_img.value = price_img.value == iconList[2] ? iconList[1] : iconList[2]
      // } else {
      //   price_img.value = iconList[0]
      // }
      emit('openAppUrl')
    }
    const showLimit = ref(false)
    const showMore = ref(true)
    const clickIntro = () => {
      showMore.value = !showMore.value
    }
    onMounted(() => {
      // const introDom = proxy.$refs.intro
      //
      // if (introDom.scrollHeight > introDom.offsetHeight) {
      //   showLimit.value = true
      // }
    })
    return {
      data_info,
      user_info,
      shop_info,
      data,
      chooseTag,
      price_img,
      goodsList,
      tagList,
      tagListRight,
      selectedIndex,
      showLimit,
      clickIntro,
      showMore
    }
  }
})
</script>

<style scoped lang="scss">
div,span{box-sizing: border-box}
.share-shop-window{
  background:#F5F5F5;
  position: relative;
  padding-top: 260px;
  width: 100%;

  .top{
    position: absolute;
    top: 0px;
    left: 0;
    height: 480px;
    width: 100%;
    padding:30px 32px 24px 32px;
    background:url("https://gpets.oss-cn-hangzhou.aliyuncs.com/v2gpets/1851133274571808768.png") no-repeat center;
    background-size: 750px 480px;
    .search-top{
      //padding-left: 52px;
      .search-img-out{
        width: 614px;
        position: relative;
        height: 64px;
        .search-img{
          position: absolute;
          left: 0;
          top:0;
          width: 614px;
          height: 64px;
        }
        .search-btn-img{
          position: relative;
          z-index: 2;
          width: 32px;
          height: 32px;
        }
        span{
          position: relative;
          z-index: 2;
          font-size: 28px;
          color: #888888;
        }
      }

      .dot-img{
        width: 40px;
        height: 40px;
      }
    }
    .shop-info{
      position: relative;
      .left{
        .shop-logo{
          border-radius: 50%;
          width: 116px;
          object-fit: cover;
          height:116px;
        }
        .shop-name{
          font-size: 32px;
          color:#111111;
          .name{
            flex-shrink: 0;
            display: inline-block;
            max-width: 250px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .shop-tag{
            position: relative;
            top: 2px;
            width: 120px;
            height: 32px;
            .tag-img{
              position: absolute;
              width: 120px;
              height: 32px;
              left: 0;
              top:0;
              &.tag-img-personal{
                width: 104px;
              }
            }
            span{
              position: relative;
              z-index: 1;
              display: inline-block;
              font-size: 22px;
              color:#666666;
            }
            .right-default{
              width: 16px;
              height: 16px;
              position: relative;
              top: 2px;
              z-index: 1;
            }
          }

        }
        .num-div{
          .fans,.goods{
            font-size: 24px;
            color: #444444;

            .num{
              color: #1A1A1A;
              font-weight: bold;
            }
          }
        }
      }
      .right-div{
        font-size: 28px;
        color:#FFFFFF;
        right: 32px;
        bottom: 8px;
        position: absolute;
        width:128px;
        height:60px;
        img{
          top: 0;
          left: 0;
          position: absolute;
          width:128px;
          height:60px;
        }
        span  {
          position: relative;
          z-index: 1;
        }
      }
    }

  }
  .content-divs{
    position: relative;
    z-index: 2;
    .tag{
      background: #FFFFFF;
      padding: 26px 32px 14px 34px;
      border-radius: 16px 16px 0 0;

      .tag-inner{
        color: #7F858E;
        font-size: 28px;
        margin-right:44px;
        &.selected{
          color:#14161A ;
        }
        img{
          width:17px;
          height:26px;
          margin-left:6px;
          position: relative;
          top: 8px;
        }
      }
    }
    .intro{
      color: #333333;
      font-size: 24px;
      position: relative;
      &.hasPadding{
        padding-right: 30px;
      }
      .up-down{
        display: inline-block;
        width: 17px;
        height: 11px;
        background: url("../../../assets/share/down.png") no-repeat;
        background-size: 100%;
        position: absolute;
        right: 10px;
        top: 14px;
        &.up{
          background: url("../../../assets/share/up.png") no-repeat;
          background-size: 100%;
          position:relative;
          top: -4px;
        }
      }
    }
    .list-div{
      padding: 16px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      background: #F6F6F6;
      flex-wrap: wrap;

      .list{
        background: #fcfcfc;
        margin-bottom: 16px;
        width:350px;
        .title{
          padding: 16px;
          color: #333333;
          font-size: 28px;
          height:86px;
        }
        .price{
          padding: 16px;
          font-size: 24px;
          color:#676767;

          .price-span{
            color: #000000;
            font-size: 36px;
          }
        }
        img{
          width:350px;
          background: white;
          height: 350px;
          object-fit: cover;
        }
      }
    }
  }
 .empty{
   margin-top: 0;
   position: relative;
   z-index: 2;
   padding-top: 0;
   background: white;
 }
}

</style>
